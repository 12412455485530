const elementor = {
  dependencies: {
    general: [
      'jquery.min.js',
      'jquery-migrate.min.js',
      'core.min.js',
      'mouse.min.js',
      'draggable.min.js',
      'underscore.min.js',
      'backbone.min.js',
      'backbone.marionette.min.js',
      'backbone.radio.min.js',
      'swiper.min.js',
      'webpack.runtime.min.js',
      'frontend-modules.min.js',
      'waypoints.min.js',
      'frontend.min.js',
    ],
    pro: [
      'webpack-pro.runtime.min.js',
      'frontend.min.js',
      'elements-handlers.min.js',
    ],
  },
  config: {
    general: `const ElementorProFrontendConfig = {
      ajaxurl: '${process.env.NEXT_PUBLIC_BASE_WP_API_URL}/wp-admin/admin-ajax.php',
      urls: {
        assets: '${process.env.NEXT_PUBLIC_BASE_WP_API_URL}/wp-content/plugins/elementor-pro/assets/',
        rest: '${process.env.NEXT_PUBLIC_BASE_WP_API_URL}/wp-json/'
      }
    };`,
    pro: `const elementorFrontendConfig = {
      environmentMode: { edit: false, wpPreview: false },
      is_rtl: false,
      breakpoints: { xs: 0, sm: 480, md: 768, lg: 1025, xl: 1440, xxl: 1600 },
      version: '3.18.0',
      urls: {
        assets:
          '${process.env.NEXT_PUBLIC_BASE_WP_API_URL}/wp-content/plugins/elementor/assets/',
      },
      settings: {
        page: [],
        general: {
          elementor_global_image_lightbox: 'yes',
          elementor_enable_lightbox_in_editor: 'yes',
        },
      },
      post: {},
      experimentalFeatures: {},
      responsive: {
        "breakpoints": {
          "mobile": {
            "label": "Mobile Portrait",
            "value": 767,
            "default_value": 767,
            "direction": "max",
            "is_enabled": true
          },
          "mobile_extra": {
            "label": "Mobile Landscape",
            "value": 880,
            "default_value": 880,
            "direction": "max",
            "is_enabled": false
          },
          "tablet": {
            "label": "Tablet Portrait",
            "value": 1024,
            "default_value": 1024,
            "direction": "max",
            "is_enabled": true
          },
          "tablet_extra": {
            "label": "Tablet Landscape",
            "value": 1200,
            "default_value": 1200,
            "direction": "max",
            "is_enabled": false
          },
          "laptop": {
            "label": "Laptop",
            "value": 1366,
            "default_value": 1366,
            "direction": "max",
            "is_enabled": false
          },
          "widescreen": {
            "label": "Widescreen",
            "value": 2400,
            "default_value": 2400,
            "direction": "min",
            "is_enabled": false
          }
        }
      }
    };`,
  },
} as const

const ElementorScripts = () => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: elementor.config.pro,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: elementor.config.general,
        }}
        type="text/javascript"
      />
      {elementor.dependencies.general.map((fileName) => (
        <script
          defer
          key={fileName}
          src={`/assets/scripts/elementor/${fileName}`}
        />
      ))}
      {elementor.dependencies.pro.map((fileName) => (
        <script
          defer
          key={fileName}
          src={`/assets/scripts/elementor-pro/${fileName}`}
        />
      ))}
    </>
  )
}

export { ElementorScripts }
