import React, { useMemo } from 'react'
import Script from 'next/script'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import type { CombinedListing } from './staticData'

import type {
  AlgoliaListing,
  BoomiListing,
  BreadcrumbListItem,
  Review,
  YoastMetadata,
} from 'types/externalData'
import type { DetailedLocationData } from 'types/locationLanding'

type Listing = Partial<
  AlgoliaListing &
    Pick<BoomiListing, Exclude<keyof BoomiListing, keyof AlgoliaListing>>
>

type Location = {
  address: string
  city: string
  country: string
  country_short: string
  lat: number
  lng: number
  place_id: string
  post_code: string
  state: string
  state_short: string
  zoom: number
}

type FaqData = { question: string; answer: string }[]

export const buildDescription = (listing) => {
  if (listing && 'adContent' in listing && listing['adContent'].length) {
    const description = listing['adContent'].filter(
      ({ type }: BoomiListing['adContent'][0]) => type === 'summary',
    )
    if (description) {
      return description[0]['text']
    }
  }

  return ''
}

export const buildMetaDescription = (listing: CombinedListing) => {
  let description = ''

  if (listing && 'adContent' in listing && listing['adContent'].length) {
    description = `Rent this vacation home in ${listing.City}, ${
      listing.State
    } – Sleeps ${listing['Max Occupancy']} guests • ${
      listing.Bedrooms
    } Bedrooms • ${listing.Bathrooms} Bathrooms • $${
      listing['Average Per Night']
    } avg/night • Read ${listing['Number of Reviews']} reviews and view${
      listing.images?.length ? ` ${listing.images.length} ` : ' '
    }photos!`

    const firstSentence = listing['adContent']
      .filter(({ type }: BoomiListing['adContent'][0]) => type === 'summary')[0]
      .text.match(/(.*?)[.?!]/)
      ? listing['adContent']
          .filter(
            ({ type }: BoomiListing['adContent'][0]) => type === 'summary',
          )[0]
          .text.match(/(.*?)[.?!]/)[0]
      : ''

    description += ` ${firstSentence}`
  }

  return description
}

const buildBestRating = (reviews: Review[]) => {
  let best = 0

  if (!reviews || !reviews.length) {
    return ''
  }

  for (let i = 0; i < reviews.length; i++) {
    const review = reviews[i]

    if ('rating' in review && +review['rating'] > best) {
      best = +review['rating']
    }
  }

  return best
}

const buildWorstRating = (reviews: Review[]) => {
  let worst = 5

  if (!reviews || !reviews.length) {
    return ''
  }

  for (let i = 0; i < reviews.length; i++) {
    const review = reviews[i]

    if ('rating' in review && +review['rating'] < worst) {
      worst = +review['rating']
    }
  }

  return worst
}

const buildSeoImage = (listing: CombinedListing) => {
  if ('ImageUrl' in listing) {
    return `https://res.cloudinary.com/evolve-vacation-rental-network/image/upload/ar_16:9,c_fill,dpr_2.0,f_auto,q_auto,w_320/v1/${listing['ImageUrl']}`
  } else if (
    'units' in listing &&
    listing['units'].length &&
    'images' in listing['units'][0] &&
    listing['units'][0]['images'].length
  ) {
    return listing['units'][0]['images'][0]['url']
  }

  return ''
}

const determinePetsAllowed = (listing: CombinedListing) => {
  if (
    listing &&
    'units' in listing &&
    listing['units'].length &&
    'amenities' in listing['units'][0]
  ) {
    return listing['units'][0]['amenities'].some(
      (amenity: BoomiListing['units'][0]['amenities'][0]) =>
        amenity.name.toLowerCase() === 'pets allowed',
    )
  }

  return false
}

const buildReviewCount = (listing: CombinedListing) => {
  if (
    listing['Number of Reviews'] ||
    (listing['Number of Reviews'] && +listing['Number of Reviews'] === 0)
  ) {
    return listing['Number of Reviews']
  } else if (
    listing['number of reviews'] ||
    +listing['number of reviews'] === 0
  ) {
    return listing['number of reviews']
  }

  return 0
}

const buildAggregateReview = (listing: CombinedListing, reviews: Review[]) => {
  if (listing['number of reviews'] && +listing['number of reviews'] > 0) {
    return {
      aggregateRating: {
        '@type': 'AggregateRating',
        'itemReviewed': 'listing',
        'ratingValue': listing['Average Rating'],
        'reviewCount': buildReviewCount(listing),
        'bestRating': buildBestRating(reviews),
        'worstRating': buildWorstRating(reviews),
      },
    }
  }

  return ''
}

const buildAddress = (regionList: string[], localityList: string[]) => ({
  '@type': 'PostalAddress',
  'addressRegion': regionList[0] ?? regionList[1],
  'addressLocality': localityList[0] ?? localityList[1],
})

const getTopAmenities = (
  amenityList: BoomiListing['units'][0]['amenities'],
) => {
  const sortOrder: { [key: string]: number } = {
    'Pool': 0,
    'Private Hot Tub': 1,
    'Beachfront': 2,
    'Oceanfront': 3,
    'Wireless Internet (WIFI)': 4,
    'Air-Conditioning': 5,
    'Community Pool': 6,
    'Kitchen': 7,
    'Parking': 8,
  }

  const filteredAmenities: BoomiListing['units'][0]['amenities'] = []

  for (let i = 0; i < amenityList.length; i++) {
    for (let j = 0; j < Object.keys(sortOrder).length; j++) {
      if (Object.keys(sortOrder)[j] === amenityList[i].name) {
        filteredAmenities.push(amenityList[i])
      }
    }
    // Count Private Pool, Pirvate Indoor Pool, Private Outdoor Pool as 'Pool'
    if (amenityList[i].name.match(/Private( [^\s]+ |\s)Pool/gi)) {
      filteredAmenities.push({ name: 'Pool', category: 'Pool Amenities' })
    }
  }

  return filteredAmenities.sort((a, b) => sortOrder[a.name] - sortOrder[b.name])
}

const buildAmenityFeatures = (
  amenityList: BoomiListing['units'][0]['amenities'],
) => {
  return getTopAmenities(amenityList).map((amenity) => ({
    '@type': 'LocationFeatureSpecification',
    'name': amenity.name,
    'value': 'TRUE',
  }))
}

const StructuredDataComponent = ({
  data,
}: {
  data: { [key: string]: any }
}) => (
  <Script
    dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    id="structured-data"
    type="application/ld+json"
  />
)

export const BreadcrumbsStructuredData = ({
  elements,
}: {
  elements: BreadcrumbListItem[]
}) => {
  const itemListElements: BreadcrumbListItem[] = useMemo(() => {
    return elements.map((element, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      ...element,
    }))
  }, [elements])

  const data = useMemo(() => {
    if (itemListElements.length === 0) {
      return null
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'name': itemListElements[itemListElements.length - 1].name,
      'itemListElement': itemListElements,
    }
  }, [itemListElements])

  if (!data) {
    return null
  }

  return <StructuredDataComponent data={data} />
}

export const ListingStructuredData = ({
  listing,
  reviews,
}: {
  listing: CombinedListing
  reviews: Review[]
}) => {
  const amenityFeatures = useMemo(
    () => buildAmenityFeatures(listing.units[0].amenities),
    [listing.units],
  )
  const data = useMemo(
    () => ({
      '@context': 'https://schema.org/',
      '@type': 'LodgingBusiness',
      '@id': listing.objectID,
      'name': listing.Headline,
      'image': [buildSeoImage(listing)],
      'description': buildDescription(listing),
      'petsAllowed': determinePetsAllowed(listing) === true ? 'TRUE' : 'FALSE',
      'address': buildAddress(
        [
          listing.units[0].addresses[0].stateShort,
          listing.units[0].addresses[0].countryShort,
        ],
        [
          listing.units[0].addresses[0].locality,
          listing.units[0].addresses[0].state,
        ],
      ),
      'amenityFeature':
        amenityFeatures.length > 3
          ? amenityFeatures.slice(0, 3)
          : amenityFeatures,
      'numberOfRooms': listing.units[0].configuration.bedrooms,
      'checkinTime': listing['Check-in Time'],
      'checkoutTime': listing['Check-out Time'],
      'brand': {
        '@type': 'Brand',
        'name': 'Evolve Vacation Rental',
      },
      ...('Number of Reviews' in listing || 'number of reviews' in listing
        ? buildAggregateReview(listing, reviews)
        : ''),
    }),
    [amenityFeatures, listing, reviews],
  )

  return <StructuredDataComponent data={data} />
}

export const FaqStructuredData = ({ faqData }: { faqData: FaqData }) => (
  <StructuredDataComponent
    data={{
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      'mainEntity': faqData.map((faq: FaqData[0]) => ({
        '@type': 'Question',
        'name': faq.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': faq.answer,
        },
      })),
    }}
  />
)

export const WebpageStructuredData = ({
  yoastData,
  canonicalUrl,
  name,
  description,
}: {
  yoastData: YoastMetadata
  canonicalUrl?: string
  name?: string
  description?: string
}) => {
  const router = useAugmentedRouter()
  return (
    <StructuredDataComponent
      data={{
        '@context': 'https://schema.org/',
        '@type': 'Webpage',
        'name': yoastData.yoast_wpseo_title
          ? yoastData.yoast_wpseo_title
          : name,
        'description': yoastData.yoast_wpseo_metadesc
          ? yoastData.yoast_wpseo_metadesc
          : description,
        'url': canonicalUrl
          ? canonicalUrl
          : process.env.NEXT_PUBLIC_ORIGIN + router.asPath.split('?')[0],
      }}
    />
  )
}

export const CollectionPageStructuredData = ({
  name,
  description,
  about,
  language,
  image,
}: {
  name: string
  description: string
  about: string
  language: string
  image: string
}) => {
  const router = useAugmentedRouter()
  return (
    <StructuredDataComponent
      data={{
        '@context': 'https://schema.org/',
        '@type': 'CollectionPage',
        'name': name,
        'description': description,
        'about': about,
        'inLanguage': language,
        'url': process.env.NEXT_PUBLIC_ORIGIN + router.asPath.split('?')[0],
        'primaryImageOfPage': image,
      }}
    />
  )
}

export const ArticleStructuredData = ({ article, url }) => (
  <StructuredDataComponent
    data={{
      '@context': 'https://schema.org/',
      '@type': 'BlogPosting',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': url,
      },
      'headline': article.yoast_meta.yoast_wpseo_title
        ? article.yoast_meta.yoast_wpseo_title
        : article.title.rendered,
      'image': article.featured_image_url,
      'author': {
        '@type': 'Person',
        'name': article.author_details.nickname,
      },
      'publisher': {
        '@type': 'Organization',
        'name': 'Evolve Vacation Rental',
        'logo': {
          '@type': 'ImageObject',
          'url': `${process.env.NEXT_PUBLIC_ORIGIN}/assets/logos/evolve-logo.png`,
          'height': '48px',
          'width': '240px',
        },
      },
      'description': article.yoast_meta.yoast_wpseo_metadesc
        ? article.yoast_meta.yoast_wpseo_metadesc
        : article.excerpt.rendered,
      'datePublished': article.date,
      'dateModified': article.modified,
      'inLanguage': 'en-US',
    }}
  />
)

export const OrganizationStructuredData = () => (
  <StructuredDataComponent
    data={{
      '@context': 'https://schema.org/',
      '@type': 'Organization',
      'name': 'Evolve Vacation Rental',
      'description': 'Vacation Rentals Made Easy',
      'url': process.env.NEXT_PUBLIC_ORIGIN,
      'logo': {
        '@type': 'ImageObject',
        'url': `${process.env.NEXT_PUBLIC_ORIGIN}/assets/logos/evolve-logo.png`,
      },
    }}
  />
)

export const LocationPagePlaceStructuredData = ({
  location,
}: {
  location: Location
}) => (
  <StructuredDataComponent
    data={{
      '@context': 'https://schema.org/',
      '@type': 'Place',
      'name': location.address.split(',')[0] + ' Vacation Rentals',
      'address': buildAddress(
        [location.state_short, location.country_short],
        [location.address.split(',')[0], location.state],
      ),
    }}
  />
)

export const AutoLocationPagePlaceStructuredData = ({
  cityName,
  slugArr,
  stateData,
}: {
  cityName: string
  slugArr: string[]
  stateData: DetailedLocationData
}) => {
  const upperCaseSlugArr = useMemo(
    () => slugArr.map((el) => el.toUpperCase()),
    [slugArr],
  )
  return (
    <StructuredDataComponent
      data={{
        '@context': 'https://schema.org/',
        '@type': 'Place',
        'name': `${cityName} Vacation Rentals`,
        'address': buildAddress(
          [upperCaseSlugArr[1], upperCaseSlugArr[0]],
          [cityName, stateData?.name ?? upperCaseSlugArr[1]],
        ),
      }}
    />
  )
}

export const DateModifiedStructuredData = ({ date }: { date: Date }) => (
  <StructuredDataComponent
    data={{
      '@context': 'https://schema.org',
      '@type': 'ItemPage',
      'dateModified': date,
    }}
  />
)
